<script>
import {UPopover, UDialog, UInput, UField} from 'uloc-vue'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import {enviaTeste, upgradeCampanha, importarListaManual} from '@/domain/marketing/services'
// import {donwloadFile} from "@/utils/downloadFile"

export default {
  name: 'AcaoMkt',
  inject: ['container'],
  components: {MenuOptionsItem, UPopover, UDialog, UInput, UField},
  computed: {
    campanha () {
      return this.container.campanha
    }
  },
  data() {
    return {
      testeDialog: false,
      testeData: {
        destinatario: null
      },
      increverManual: false,
      importando: false,
      lista: ''
    }
  },
  methods: {
    editar () {
      this.$router.push({name: 'marketing.criar-campanha.canal', params: {
        id: this.campanha.id,
        canal: this.campanha.canal,
        action: 'destinatarios',
      }})
    },
    testar () {
      this.testeDialog = true
      setTimeout(() => {
        this.$refs.testeEmailInput.focus()
      }, 500)
    },
    atualizarInscritos () {
      this.$uloc.loading.show()
      upgradeCampanha(this.campanha.id, {
        status: 0
      })
          .then(response => {
            this.$uloc.loading.hide()
            this.container.load()
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    enviarTeste () {
      if (!this.testeData.destinatario || !this.testeData.destinatario.length) {
        this.$uloc.dialog({title: 'Atenção', message: 'Informe um e-mail para enviar o teste'})
        // this.$refs.testeEmailInput.focus()
        return
      }
      this.$uloc.loading.show()
      enviaTeste(this.campanha.id, {destinatario: this.testeData.destinatario})
          .then(response => {
            this.$uloc.loading.hide()
            this.testeDialog = false
            this.dg('Teste enviado com sucesso!')
          })
          .catch(error => {
            this.alertApiError(error)
            this.$uloc.loading.hide()
          })
    },
    adicionarInscritos () {
      this.increverManual = true
    },
    importar () {
      this.importando = true
      importarListaManual(this.campanha.id, this.lista)
          .then(response => {
            this.dg('Importado com sucesso!')
            this.importando = false
            this.lista = ''
            this.$emit('update')
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    fechar () {
      this.increverManual = false
      this.importando = false
    }
  }
}
</script>

<template>
  <div>
    <div class="increverManualModal" v-if="increverManual">
      <div class="modalContent">
        <div class="dheader">
        Importar lista manualmente para a campanha:<br><br>
        </div>
        <div class="areaTexto">
        <textarea autofocus v-model="lista" style="width: 100%; height: 100%" placeholder="Nome;Email ou somente Email em cada linha"></textarea>
        </div>
        <div class="opts m-t">
          <u-btn @click="importar" :loading="importando" color="green" no-caps label="Importar" />
          <u-btn @click="fechar" color="white" no-caps label="Fechar" class="m-l text-black" />
        </div>
      </div>
    </div>
    <e-btn align="left" label="Ações">
      <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
      <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
        <div class="menu_ menu-btn-leilao">
          <ul>
            <menu-options-item @click="editar" label="Editar Campanha" close  />
            <menu-options-item @click="atualizarInscritos" label="Atualizar Inscritos" close  />
            <menu-options-item @click="adicionarInscritos" label="Inscrever Manualmente" close  />
            <menu-options-item @click="testar" label="Enviar um teste" close  />
          </ul>
          <!--        <ul>
                    <menu-options-item label="Imprimir" close  />
                  </ul>--> <!-- @TODO -->
          <slot />
        </div>
      </u-popover>
    </e-btn>
    <u-dialog
        v-model="testeDialog"
        @cancel="testeDialog = false"
    >
      <span slot="title">Enviar teste de campanha</span>

      <!-- @TODO: Implementar canais -->

      <div slot="body">
        <u-field
            icon="mail"
            label="Informe o e-mail que deseja enviar um teste:"
            orientation="vertical"
        >
          <u-input required ref="testeEmailInput" autofocus v-model="testeData.destinatario" />
        </u-field>
      </div>

      <template slot="buttons" slot-scope="props">
        <u-btn flat label="Cancelar" @click="props.cancel" />
        <u-btn color="primary" label="Enviar teste" @click="enviarTeste" />
      </template>
    </u-dialog>
  </div>
</template>

<style lang="stylus">
.increverManualModal {
  position fixed
  left 0
  width 100%
  top 0
  bottom 0
  z-index 9000
  display flex
  align-items center
  justify-content center
  .modalContent {
    text-align left
    position absolute
    padding 20px
    background-color #e8e8e8
    margin auto
    width 70%
    max-width 700px
    height 70%
    -webkit-box-shadow: 0 2px 14px 0 rgba(97, 123, 160, 1);
    box-shadow: 0 2px 14px 0 rgba(97, 123, 160, 1);
    border-radius 10px
    display flex
    justify-content space-between
    flex-direction column
    .areaTexto {
      flex 1
      textarea {
        padding 10px
      }
    }
  }
}
</style>
