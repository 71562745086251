import http from "@/utils/services/http"

export const gerarConteudo = (data) => {
    let url = `/api/marketing/gerarConteudo`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const list = (limit, page, filtros, basic) => {
    let url = '/api/mkt/campanhas?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id) => {
    let url = `/api/mkt/campanhas/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newCampanha = (data) => {
    let url = `/api/mkt/campanhas`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateCampanha = (id, data) => {
    let url = `/api/mkt/campanhas/${id}`
    return http.put(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const upgradeCampanha = (id, data) => {
    let url = `/api/mkt/campanhas/${id}`
    return http.patch(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteCampanha = (id) => {
    let url = `/api/mkt/campanhas/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listInscritos = (campanha, limit, page, filtros, basic) => {
    let url = '/api/mkt/campanhas/' + campanha + '/inscritos?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteInscricao = (campanha, inscrito) => {
    let url = `/api/mkt/campanhas/${campanha}/inscritos/${inscrito}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const enviarCampanhaInscricao = (campanha, inscrito) => {
    let url = `/api/mkt/campanhas/${campanha}/inscritos/${inscrito}/enviar`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const enviaTeste = (id, data) => {
    let url = `/api/mkt/campanhas/${id}/enviarTeste`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const importarListaManual = (id, lista) => {
    let url = `/api/mkt/campanhas/${id}/importarLista`
    return http.post(url, {
        lista: lista
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
